.refers {
    width: 100%;
    height: 600px;
    background-image: url('./../images/refers-bg.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__header {
        font-size: 36px;
        color: white;
        font-weight: 600;
        margin: 0;
    }
}

.slider {
    width: 100%;
    margin: 50px 0;
    &__item {
        width: 100%;
        height: 270px;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    &__content {
        width: 800px;
        height: 100%;
        background-color: rgba(#ffffff, .9);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 50px;
    }
    &__image {
        width: 30%;
    }
    &__text {
        width: 65%;
    }
}

.slick-dots {
    display: flex;
    padding: 0;
    li {
        display: block;
        button {
            background: none;
            border: 2px solid white;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            margin: 0 10px;
            padding: 0;
            content: "";
            transition: all .15s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
    }
    .slick-active {
        .dot-active {
            background-color: white;
        }
    }
}

.dot-active {
    width: 7px;
    height: 7px;
    transition: all .15s ease-in-out;
    border-radius: 50%;
}

@media(max-width: 1023px) {
    .refers {
        height: auto;
        background-image: url('./../images/refers-bg-mobile.png');
        padding: 30px 0;
    }
    .slider {
        &__item {
            height: auto;
        }
        &__image {
            margin-bottom: 30px;
            width: 200px;
        }
        &__content {
            width: 90%;
            box-sizing: border-box;
            flex-direction: column;
            padding: 50px 30px;
        }
        &__text {
            width: 100%;
        }
    }
}

@media(max-width: 500px) {
    .slider {
        margin: 30px 0;
        &__item {
            height: auto;
        }
        &__image {
            width: 150px;
        }
        &__content {
            width: 90%;
            box-sizing: border-box;
            flex-direction: column;
            padding: 50px 30px;
        }
        &__text {
            font-size: 2.8vw;
            line-height: 150%;
        }
    }
}