.footer {
    width: 100%;
    height: 160px;
    background-color: #181919;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &__arrow {
        width: 60px;
        height: 60px;
        border: 9px solid #181919;
        background-color: #313336;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: relative;
        top: -25px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.2);
        }
    }
    &__image {
        width: 50%;
    }
    &__wrapper {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    &__copyright {
        color: #6a6e75;
        font-size: 10px;
    }
    &__alea {
        position: absolute;
        left: 0;
        width: 70px;
    }
    &__globe {
        margin-left: 10px;
        width: 100px;
    }
    &__project {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #6a6e75;
        font-size: 10px;
    }
}

@media(max-width: 1023px) {
    .footer {
        height: auto;
        &__wrapper {
            flex-direction: column;
            justify-content: space-between;
            height: 150px;
            position: relative;
            top: -20px;
            width: 90%;
            margin: 20px 0;
        }
        &__copyright {
            order: -1;
        }
        &__alea {
            position: static;
        }
        &__project {
            position: static;
        }
        &__arrow {
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 7px solid #181919;
            border-radius: 50%;
            position: relative;
            top: -25px;
            transition: all .2s ease-in-out;
            cursor: pointer;
            &:hover {
                transform: scale(1.2);
            }
        }
    }
}