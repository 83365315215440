@import '../helpers/colors';
@import '../helpers/fonts';

body, html, p, a, section, div, input {
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
    color: #444444;
}

.mar-bot-50 {
    margin-bottom: 50px !important;
    @media(max-width: 500px) {
        margin-bottom: 30px !important;
    }
}

.mar-left-10 {
    margin-left: 10px !important;
}

@import '../partials/banner';
@import '../partials/form';
@import '../partials/profit';
@import '../partials/steps';
@import '../partials/refers';
@import '../partials/contact';
@import '../partials/footer';