.profit {
    display: flex;
    justify-content: center;
    align-items: center;
    &__wrapper {
        width: 75%;
        margin: 80px 0;
    }
    &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__image {
        width: 49%;
        height: 330px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &--one {
            background-image: url('./../images/photo1.png');
        }
        &--two {
            background-image: url('./../images/photo2.png');
        }
    }
    &__text {
        width: 49%;
        padding: 15px;
        box-sizing: border-box;
    }
    &__header {
        font-size: 36px;
        font-weight: 700;
        margin-top: 0;
    }
    &__paragraph {
        font-weight: 200;
        font-size: 14px;
        color: #666666;
        line-height: 150%;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media(max-width: 1023px) {
    .profit {
        &__wrapper {
            width: 90%;
        }
        &__content {
            flex-direction: column-reverse;
            margin-bottom: 0;
            &:first-child {
                flex-direction: column;
            }
        }
        &__image {
            width: 100%;
        }
        &__text {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__paragraph {
            align-self: flex-start;
        }
        &__header {
            max-width: 90%;
        }
    }
}

@media(max-width: 550px) {
    .profit {
        &__wrapper {
            margin: 30px 0 15px;
        }
        &__image {
            height: 60vw;
        }
        &__header {
            font-size: 6vw;
        }
        &__paragraph {
            font-size: 2.8vw;
        }
    }
}