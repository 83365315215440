.steps {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    &__wrapper {
        width: 75%;
        margin: 80px 0;
    }
    &__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-repeat: no-repeat;
        background-size: auto 250px;
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
        &--a {
            background-image: url('./../images/a.png');
            background-position: 60%;
        }
        &--b {
            background-image: url('./../images/b.png');
            background-position: 40%;
        }
        &--c {
            background-image: url('./../images/c.png');
            background-position: 60%;
        }
    }
    &__image {
        width: 49%;
        height: 330px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 10;
        position: relative;
        &--one {
            background-image: url('./../images/photo3.png');
        }
        &--two {
            background-image: url('./../images/photo4.png');
        }
        &--three {
            background-image: url('./../images/photo5.png');
            align-self: flex-start;
        }
    }
    &__text {
        width: 49%;
        padding: 15px;
        box-sizing: border-box;
        z-index: 10;
    }
    &__header {
        font-size: 24px;
        font-weight: 700;
        margin-top: 0;
        max-width: 95%;
        margin-bottom: 15px;
    }
    &__paragraph {
        font-weight: 200;
        font-size: 14px;
        color: #666666;
        line-height: 150%;
    }
    &__title {
        font-weight: bold;
        font-size: 14px;
        color: #666666;
        line-height: 150%;
    }
}

@media(max-width: 1023px) {
    .steps {
        &__wrapper {
            width: 90%;
        }
        &__content {
            flex-direction: column;
            margin-bottom: 0;
            &:nth-child(2) {
                flex-direction: column-reverse;
            }
        }
        &__image {
            width: 100%;
        }
        &__text {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        &__paragraph {
            align-self: flex-start;
        }
        &__header {
            max-width: 90%;
        }
    }
}

@media(max-width: 550px) {
    .steps {
        &__wrapper {
            margin: 30px 0 15px;
        }
        &__image {
            height: 60vw;
        }
        &__header {
            font-size: 4vw;
        }
        &__paragraph {
            font-size: 2.8vw;
        }
        &__title {
            font-size: 2.8vw;
        }
    }
}