.banner {
    width: 100%;
    background-image: url('./../images/home-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    &__wrapper {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 30px 0 80px;
    }
    &__left {

    }
    &__logo {
        width: 250px;
    }
    &__header {
        font-size: 4vw;
        color: white;
        margin-top: 170px;
    }
}

@media(max-width: 1023px) {
    .banner {
        &__wrapper {
            flex-direction: column;
            align-items: center;
            width: 90%;
            margin: 50px 0;
        }
        &__header {
            margin-top: 50px;
            font-size: 40px;
        }
    }
}

@media(max-width: 500px) {
    .banner {
        background-image: url('./../images/home-bg-mobile.png');
        &__wrapper {
            margin: 30px 0;
        }
        &__header {
            font-size: 8vw;
            margin: 30px auto;
        }
        &__logo {
            width: 70%;
        }
    }
}