@keyframes phone {
    0%   {transform: rotate(10deg)}
    20%  {transform: rotate(-10deg)}
    40%  {transform: rotate(10deg)}
    80%  {transform: rotate(-10deg)}
    100% {transform: rotate(10deg)}
}

.form {
    background-color: rgba(#ffffff, .8);
    color: #444444;
    padding: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 47%;
    box-sizing: border-box;
    margin-top: 50px;
    &__header {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 50px;
    }
    &__input {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 15px;
        border: 1px solid transparent;
        border-radius: 20px;
        transition: all .15s ease-in-out;
        &:focus {
            outline: none;
            border: 1px solid #444444;
        }
    }
    &__label {
        align-self: flex-start;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
    }
    &__text {
        max-width: 95%;
        font-size: 13px;
    }
    &__checkbox {
        -webkit-appearance: none;
        width: 15px;
        height: 15px;
        border: 1px solid #d7d7d7;
        background-color: white;
        display: flex;
        transition: all .15s ease-in-out;
        &:focus {
            outline: none;
        }
        &--inside {
            width: 9px;
            height: 9px;
            background-color: #444444;
            position: absolute;
            top: 3px;
            left: 3px;
            opacity: 0;
            transition: all .15s ease-in-out;
        }
        &--active {
            opacity: 1;
        }
    }
    &__submit {
        padding: 10px 40px;
        border: 1px solid #444444;
        background-color: transparent;
        border-radius: 25px;
        transition: all .15s ease-in-out;
        &:hover {
            cursor: pointer;
            background-color: #444444;
            color: white;
        }
    }
    &__phone {
        justify-content: space-between;
        align-items: center;
        width: 175px;
        height: 40px;
        margin-top: 30px;
        text-decoration: none;
        display: none;
    }
    &__number {
        line-height: 150%;
    }
    &__image {
        height: 100%;
        animation-name: phone;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
}

@media(max-width: 1023px) {
    .form {
        margin-top: 0px;
        width: 100%;
        max-width: 600px;
        &__header {
            font-size: 26px;
            margin-bottom: 30px;
        }
    }
}

@media(max-width: 500px) {
    .form {
        margin-top: 0px;
        width: 100%;
        max-width: 600px;
        padding: 8vw 5vw;
        &__header {
            font-size: 5vw;
        }
        &__input {
            height: 34px;
            margin-bottom: 10px;
        }
        &__text {
            font-size: 2.5vw;
            max-width: 90%;
        }
        &__phone {
            display: flex;
        }
    }
}