.contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 70px;
    &__header {
        font-size: 36px;
        color: #444;
        font-weight: 600;
        margin: 0;
    }
    &__text {
        font-size: 36px;
        color: #444;
        font-weight: 200;
        margin: 30px 0 20px;
        text-align: center;
    }
    &__phone {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        color: #444;
        font-weight: 200;
        height: 36px;
        margin-bottom: 70px;
        text-decoration: none;
    }
    &__icon {
        height: 100%;
        margin-right: 10px;
    }
    &__map {
        width: 100%;
        height: 300px;
    }
}

@media(max-width: 500px) {
    .contact {
        margin-top: 30px;
        &__header {
            font-weight: 700;
            font-size: 8vw;
        }
        &__text {
            font-size: 6vw;
            font-weight: 400;
        }
        &__phone {
            font-size: 6vw;
            font-weight: 400;
            margin-bottom: 30px;
        }
        &__map {
            height: 200px;
        }
    }
}